import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {
    AiOutlineHome,
    AiOutlineFundProjectionScreen,
    AiOutlineUser, AiOutlineContacts,
} from "react-icons/ai";

import {CgFileDocument} from "react-icons/cg";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);

    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
            <Container>
                <Navbar.Brand href="/" className="d-flex">
                    <img src={logo} className="img-fluid logo" alt="brand"/>
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                <AiOutlineHome style={{marginBottom: "2px"}}/> Présentation
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/profil"
                                onClick={() => updateExpanded(false)}
                            >
                                <AiOutlineUser style={{marginBottom: "2px"}}/> Profil
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/projets"
                                onClick={() => updateExpanded(false)}
                            >
                                <AiOutlineFundProjectionScreen
                                    style={{marginBottom: "2px"}}
                                />{" "}
                                Projets
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/cv"
                                onClick={() => updateExpanded(false)}
                            >
                                <CgFileDocument style={{marginBottom: "2px"}}/> CV
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="fork-btn ">
                            <Button
                                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3LBlxM1LtvfHaTT3tFJ9vMvie91u8Uxl1pIOoZdHDAFpRVlSA5b85ra77TStefHosJP9uupGjo"
                                target="_blank"
                                className="fork-btn-inner d-flex justify-content-center"
                            >
                                <AiOutlineContacts style={{fontSize: "1.4em"}}/>
                                <span style={{marginLeft:"6px"}}>Prendre rdv</span>
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
